@if (isLoading) {
  <div class="component-loading"></div>
} @else {
  <smallstack-form
    [schema]="formSchema"
    class="flex flex-col gap-2"
    [(value)]="loginData"
    (submitForm)="loginBtn.click()"
  >
    <smallstack-form-table></smallstack-form-table>
  </smallstack-form>
  <button
    class="btn btn-primary btn-block mt-4"
    [ngClass]="{ 'btn-disabled': !loginData?.username || !loginData?.password }"
    [loadingFn]="login()"
    #progressBarBtn="loadingFn"
    data-test="backoffice-login-btn"
    #loginBtn
  >
    Anmelden
  </button>
}
