import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { AxiosApiClient, CurrentUserDto, UsersApi } from "@smallstack/axios-api-client";
import { ContextService, LoadingElementDirective } from "@smallstack/common-components";
import { WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { NotificationService } from "@smallstack/i18n-components";
import { TypeSchema } from "@smallstack/typesystem";
import { createFakeEmailAddress, upperCaseFirst } from "@smallstack/utils";
import { FormComponent, SchemaFormTableComponent } from "@smallstack/widget-core";
import { AxiosResponse } from "axios";
import { UserService } from "../../services/user.service";

@Component({
  selector: "smallstack-username-login",
  templateUrl: "./username-login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormComponent, SchemaFormTableComponent, NgClass, LoadingElementDirective]
})
export class UsernameLoginComponent {
  protected showUsernames: boolean = false;
  protected isLoading: boolean;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input("showUsernames")
  public set _showUsernames(showUsernames: boolean) {
    this.showUsernames = showUsernames;
    this.isLoading = true;
    this.cdr.markForCheck();
    void this.loadUsernames();
  }

  @Output()
  public readonly afterLogin: EventEmitter<void> = new EventEmitter();

  protected formSchema: TypeSchema = {
    type: "object",
    properties: {
      username: {
        type: "string",
        title: "Benutzername"
      },
      password: {
        type: "string",
        format: "password",
        title: "Passwort",
        minLength: 8
      }
    }
  };

  protected loginData: { username: string; password: string };

  constructor(
    private axiosApiClient: AxiosApiClient,
    private notificationService: NotificationService,
    private userService: UserService,
    private cdr: ChangeDetectorRef,
    private contextService: ContextService
  ) {}

  public login(): () => Promise<void> {
    return async (): Promise<void> => {
      try {
        const registerResponse: AxiosResponse<CurrentUserDto> = await this.axiosApiClient
          .get(UsersApi)
          .authenticateUserWithEmailAndPassword({
            emailPasswordLogin: {
              email: createFakeEmailAddress(
                this.loginData.username,
                this.contextService.getEvaluatedContext().tenantId
              ),
              password: this.loginData.password
            }
          });
        if (registerResponse.status === 201) {
          this.userService.setCurrentUser(registerResponse.data);
          this.afterLogin.emit();
        } else {
          this.notificationService.showStandardErrorPopup(registerResponse.data as any);
        }
      } catch (e) {
        this.notificationService.showStandardErrorPopup(e);
      }
    };
  }

  private async loadUsernames(): Promise<void> {
    this.isLoading = true;
    this.cdr.markForCheck();

    // load usernames via api
    try {
      const usernames = (await this.axiosApiClient.get(UsersApi).getAllUsernames()).data;
      this.formSchema.properties.username["x-schema-form"] = {
        inputWidget: WIDGET_FORM_INPUT_SELECT,
        values: usernames.sort().map((username) => ({
          value: username,
          label: upperCaseFirst(username)
        })),
        showActionButtons: false
      };
    } finally {
      this.isLoading = false;
      this.cdr.markForCheck();
    }
  }
}
